import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert, Box, Button,
  IconButton,
  Snackbar, Stack, Typography,
} from '@mui/material';
import { useDisclosure } from 'hooks';
import { ShipmentTemplateList } from './components/ShipmentTemplateList';

interface Props {
  menuOpen: boolean;
  onOpenMenu: VoidFunction;
  onCloseMenu: VoidFunction;
  error: string | null;
  loading: boolean;
  templates: ShipmentTemplate[];
  onTemplateClicked: (template: ShipmentTemplate) => void;
  selectedTemplateId: number | null;
  onDeleteTemplate: (template: ShipmentTemplate) => void;
  deletedTemplate: boolean;
  onCloseDeletedTemplate: VoidFunction;
  refreshTemplates: VoidFunction;
}

export function ShipmentTemplateMenu(props: Props) {
  const {
    menuOpen,
    onOpenMenu,
    onCloseMenu,
    error,
    loading,
    templates,
    onTemplateClicked,
    selectedTemplateId,
    onDeleteTemplate,
    deletedTemplate,
    onCloseDeletedTemplate,
    refreshTemplates,
  } = props;

  const { isOpen, onClose, onOpen } = useDisclosure(false);

  const onMenuItemClicked = (template: ShipmentTemplate) => {
    onOpen();
    onTemplateClicked(template);
  };

  return (
    <Box
      maxHeight={menuOpen ? '50vh' : undefined}
      sx={{
        overflowY: menuOpen ? 'scroll' : undefined,
      }}
    >
      <Stack direction="row" mt="1rem" mr="1rem">
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h6">My Templates</Typography>

        </Stack>
        <Stack direction="row" ml="auto" spacing={2}>
          {
            menuOpen && (
              <IconButton onClick={refreshTemplates}>
                <RefreshIcon />
              </IconButton>
            )
          }
          <Button
            variant="outlined"
            onClick={menuOpen ? onCloseMenu : onOpenMenu}
          >
            {menuOpen ? 'Hide My Templates' : 'Show My Templates'}
          </Button>

        </Stack>
      </Stack>
      {menuOpen && (
      <ShipmentTemplateList
        error={error}
        loading={loading}
        templates={templates}
        onTemplateClicked={onMenuItemClicked}
        selectedTemplateId={selectedTemplateId}
        onDeleteTemplate={onDeleteTemplate}
      />
      )}
      <Snackbar
        id={selectedTemplateId?.toString(10) ?? 'null'}
        open={isOpen}
        autoHideDuration={2000}
        onClose={onClose}
      >
        <Alert onClose={onClose} severity="success" sx={{ width: '100%' }}>
          Template Applied!
        </Alert>
      </Snackbar>
      <Snackbar
        id={`${deletedTemplate}`}
        open={deletedTemplate}
        autoHideDuration={2000}
        onClose={onCloseDeletedTemplate}
      >
        <Alert onClose={onCloseDeletedTemplate} severity="success" sx={{ width: '100%' }}>
          Template Deleted
        </Alert>
      </Snackbar>
    </Box>
  );
}
