import { useRoleContext } from 'hooks';
import * as React from 'react';
import { shipmentFiltersContext } from '../context';

export function useShipmentFiltersContext() {
  const shipmentFiltersCtx = React.useContext(shipmentFiltersContext);
  const { role } = useRoleContext();
  const previousRoleRef = React.useRef<null | Role>(null);

  React.useEffect(() => {
    if (previousRoleRef.current !== role) {
      previousRoleRef.current = role;
      if (role != null && role.type === 'CARRIER') {
        shipmentFiltersCtx.dispatch({ type: 'setShipmentStatus', status: 'ACTIVE' });
      } else if (role != null && role.type === 'SHIPPER') {
        shipmentFiltersCtx.dispatch({ type: 'setShipmentStatus', status: undefined });
      }
    }
  }, [role, shipmentFiltersCtx]);

  const updateOrigin = (
    locationKey: keyof ShipmentLocationFilter,
    locationValue: string[],
  ) => {
    shipmentFiltersCtx.dispatch({
      type: 'setOrigin',
      value: locationValue,
      originKey: locationKey,
    });
  };

  const updateDestination = (
    locationKey: keyof ShipmentLocationFilter,
    locationValue: string[],
  ) => {
    shipmentFiltersCtx.dispatch({
      type: 'setDestination',
      value: locationValue,
      destinationKey: locationKey,
    });
  };

  const updateOrderBy = (orderBy: string) => {
    shipmentFiltersCtx.dispatch({ type: 'setOrderBy', orderBy });
  };

  const updateEquipmentType = (equipmentTypes: string[]) => {
    shipmentFiltersCtx.dispatch({ type: 'setEquipmentType', equipments: equipmentTypes });
  };

  const updateServiceType = (serviceTypes: string[]) => {
    shipmentFiltersCtx.dispatch({ type: 'setService', services: serviceTypes });
  };

  const updateShipmentStatus = (status: ShipmentStatusValue | undefined) => {
    shipmentFiltersCtx.dispatch({ type: 'setShipmentStatus', status });
  };

  const updateCommodity = (commodity: Commodity | null) => {
    shipmentFiltersCtx.dispatch({ type: 'setCommodity', commodity });
  };

  const clearFilters = () => {
    shipmentFiltersCtx.dispatch({ type: 'clearShipmentFilters' });
  };

  const setFilters = (filters : ShipmentFilters) => {
    shipmentFiltersCtx.dispatch({ type: 'setShipmentFilters', filters });
  };

  const updateShipmentVisibility = (visibility: ShipmentVisibility) => {
    shipmentFiltersCtx.dispatch({ type: 'setShipmentVisibility', visibility });
  };

  const updateShipmentRelation = (shipmentRelation: ShipmentRelation) => {
    shipmentFiltersCtx.dispatch({ type: 'setShipmentRelationship', shipmentRelation });
  };

  const updateShipmentName = (shipmentName: string) => {
    shipmentFiltersCtx.dispatch({ type: 'setShipmentName', shipmentName });
  };

  const updateShipmentType = (shipmentTypes: string[]) => {
    shipmentFiltersCtx.dispatch({ type: 'setShipmentTypeAction', shipmentTypes });
  };

  const updateIncludeCompanyShipments = (includeCompanyShipments: boolean) => {
    shipmentFiltersCtx.dispatch({ type: 'SetIncludeCompanyShipments', value: includeCompanyShipments });
  };

  const updateIncludeBranchShipments = (setIncludeBranchShipments: boolean) => {
    shipmentFiltersCtx.dispatch({ type: 'SetIncludeBranchShipments', value: setIncludeBranchShipments });
  };

  return {
    filters: shipmentFiltersCtx.filters,
    updateOrigin,
    updateDestination,
    updateOrderBy,
    updateEquipmentType,
    updateServiceType,
    updateShipmentStatus,
    updateCommodity,
    clearFilters,
    setFilters,
    updateShipmentVisibility,
    updateShipmentRelation,
    updateShipmentName,
    updateShipmentType,
    updateIncludeCompanyShipments,
    updateIncludeBranchShipments,
  };
}
