import * as React from 'react';

interface ShipmentFiltersProviderProps {
  children: React.ReactNode
}

interface ShipmentFiltersContextState {
  filters: ShipmentFilters | null;
}

interface SetFiltersAction {
  filters: ShipmentFilters
  type: 'setShipmentFilters'
}

interface ClearFiltersAction {
  type: 'clearShipmentFilters'
}

interface SetOriginAction {
  type: 'setOrigin';
  originKey: keyof ShipmentLocationFilter;
  value: string[];
}

interface SetDestinationAction {
  type: 'setDestination';
  destinationKey: keyof ShipmentLocationFilter;
  value: string[];
}

interface SetEquipmentTypeAction{
  type: 'setEquipmentType';
  equipments: string[];
}

interface SetCommodityAction {
  type: 'setCommodity'
  commodity: Commodity | null;
}

interface SetShipmentStatusAction {
  type: 'setShipmentStatus';
  status: ShipmentStatusValue | undefined;
}

interface SetOrderByAction {
  type: 'setOrderBy';
  orderBy: string;
}

interface SetServiceAction {
  type: 'setService';
  services: string[];
}

interface SetShipmentVisibility {
  type: 'setShipmentVisibility';
  visibility: ShipmentVisibility;
}

interface SetShipmentRelationship {
  type: 'setShipmentRelationship';
  shipmentRelation: ShipmentRelation;
}

interface SetShipmentName {
  type: 'setShipmentName';
  shipmentName: string;
}

interface SetShipmentTypeAction{
  type: 'setShipmentTypeAction';
  shipmentTypes: string[];
}

interface SetIncludeBranchShipments{
  type: 'SetIncludeBranchShipments';
  value: boolean;
}

interface SetIncludeCompanyShipments{
  type: 'SetIncludeCompanyShipments';
  value: boolean;
}

type ShipmentFiltersActions = SetFiltersAction | ClearFiltersAction | SetOriginAction |
SetDestinationAction | SetEquipmentTypeAction | SetCommodityAction |
SetShipmentStatusAction | SetOrderByAction | SetServiceAction | SetShipmentVisibility
| SetShipmentRelationship | SetShipmentName |
SetShipmentTypeAction | SetIncludeBranchShipments | SetIncludeCompanyShipments;

interface ShipmentFiltersContextDispatch {
  dispatch: React.Dispatch<ShipmentFiltersActions>
}

const initialState = {
  filters: null,
};

export const shipmentFiltersContext = React
  .createContext<ShipmentFiltersContextState & ShipmentFiltersContextDispatch>({
  ...initialState,
  dispatch: () => null,
});

function reducer(
  state: ShipmentFiltersContextState,
  action: ShipmentFiltersActions,
): ShipmentFiltersContextState {
  switch (action.type) {
    case 'setShipmentFilters':
      return {
        filters: action.filters,
      };
    case 'clearShipmentFilters':
      return {
        filters: null,
      };
    case 'setOrigin':
      if (state?.filters?.origin) {
        return {
          filters: {
            ...state.filters,
            origin: {
              ...state.filters.origin,
              [action.originKey]: action.value,
            },
          },
        };
      }
      return {
        filters: {
          ...state.filters,
          origin: {
            [action.originKey]: action.value,
          },
        },
      };
    case 'setDestination':
      if (state?.filters?.destination) {
        return {
          filters: {
            ...state.filters,
            destination: {
              ...state.filters.destination,
              [action.destinationKey]: action.value,
            },
          },
        };
      }
      return {
        filters: {
          ...state.filters,
          destination: {
            [action.destinationKey]: action.value,
          },
        },
      };

    case 'setEquipmentType':
      return {
        filters: {
          ...state.filters,
          equipment_types: action.equipments,
        },
      };
    case 'setShipmentTypeAction':
      return {
        filters: {
          ...state.filters,
          shipmentTypes: action.shipmentTypes,
        },
      };
    case 'setCommodity':
      return {
        filters: {
          ...state.filters,
          commodity: action.commodity ?? undefined,
        },
      };
    case 'setShipmentStatus':
      return {
        filters: {
          ...state.filters,
          status: action.status,
        },
      };
    case 'setOrderBy':
      return {
        filters: {
          ...state.filters,
          order_by: action.orderBy,
        },
      };
    case 'setService':
      return {
        filters: {
          ...state.filters,
          services: action.services,
        },
      };
    case 'setShipmentVisibility':
      return {
        filters: {
          ...state.filters,
          shipmentVisibility: action.visibility,
        },
      };
    case 'setShipmentRelationship':
      return {
        filters: {
          ...state.filters,
          shipmentRelationship: action.shipmentRelation,
        },
      };
    case 'setShipmentName':
      return {
        filters: {
          ...state.filters,
          shipmentName: action.shipmentName,
        },
      };
    case 'SetIncludeBranchShipments':
      return {
        filters: {
          ...state.filters,
          includeBranchShipments: action.value,
        },
      };
    case 'SetIncludeCompanyShipments':
      return {
        filters: {
          ...state.filters,
          includeCompanyShipments: action.value,
        },
      };
    default:
      return state;
  }
}

export function ShipmentFiltersProvider(props: ShipmentFiltersProviderProps) {
  const { children } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const memoState = React.useMemo(() => ({ ...state, dispatch }), [state]);

  return (
    <shipmentFiltersContext.Provider value={memoState}>
      {children}
    </shipmentFiltersContext.Provider>
  );
}
